<template>
  <button
    ref="compareButton"
    type="button"
    @click="addToCompare(listing)"
    :disabled="isDisabledCompare(listing.id)"
    class="btn btn-sm btn--cta-outline"
  >
    <span class="ion-ios-browsers mr-2"> </span>&nbsp; {{ $t('general.addToCompare') }}
  </button>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    listing: {
      type: Object,
      required: true,
    },
    classButton: {
      type: String,
      default: 'btn btn-primary--outline',
    },
  },
  computed: {
    ...mapState({
      compareListings: (state) => state.v2.listing.compareListings,
    }),
  },

  methods: {
    isDisabledCompare(id) {
      return (
        (!this.$device.isDesktop && this.compareListings.length > 1) ||
        (this.$device.isDesktop && this.compareListings.length > 2) ||
        this.addDisabledButton(id)
      );
    },
    showModal(buttonName) {
      this.$store.commit('global/SHOW_MODAL', {
        modalName: buttonName,
      });
    },
    addToCompare(listing) {
      this.$store.commit('v2/listing/set_compare_listing', listing);
      this.$store.commit('v2/listing/set_compare_show', true);
    },
    addDisabledButton(id) {
      return this.compareListings.find(function (item) {
        return item.id === id;
      });
    },
  },
};
</script>
